import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "~/utils";

const textVariants = cva("transition-colors", {
  variants: {
    font: {
      serif: "font-serif",
      sans: "font-sans",
    },
    variant: {
      h1: "scroll-m-20 text-2xl lg:text-4xl font-bold tracking-tight",
      h2: "scroll-m-20 text-xl lg:text-2xl font-bold tracking-tight mb-2",
      h3: "scroll-m-20 text-lg lg:text-xl font-bold tracking-tight",
      h4: "scroll-m-20 text-md lg:text-lg font-bold tracking-tight",
      h5: "scroll-m-20 text-sm lg:text-md font-bold tracking-tight",
      p: "text-base lg:text-lg",
    },
    color: {
      brand: "text-brand",
      accent: "text-accent",
      default: "text-gray-1000",
      muted: "text-gray-600",
      "muted-2": "text-gray-700",
    },
  },
  defaultVariants: {
    variant: "p",
    color: "default",
    font: "sans",
  },
});

type TextTypes =
  | React.HTMLAttributes<HTMLHeadingElement>
  | React.HTMLAttributes<HTMLParagraphElement>
  | React.HTMLAttributes<HTMLQuoteElement>;
export type TextProps = TextTypes & VariantProps<typeof textVariants>;

export function Text({
  className,
  variant,
  color,
  children,
  font,
  ...props
}: TextProps) {
  return React.createElement(
    `${variant ? variant : "p"}`,
    {
      className: cn(textVariants({ font, variant, color, className })),
      ...props,
    },
    children,
  );
}
